/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 8 February 2021 : Aman
    * M-0003219: Account page for user to view their details and update their password
**************************************************************************************************************
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, change } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Form, Glyphicon, Row, Col } from 'react-bootstrap';

import FormField from '../../../components/FormFields';
import { passwordPolicy } from '../../../config/validations';

const validate = values => {
  const errors = {};
  if (values.newPassword && values.confirmNewPassword) {
    if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = 'New password and Confirm password do not match';
    }
    if (values.oldPassword === values.confirmNewPassword) {
      errors.confirmNewPassword = 'New password and Old password are same !';
    }
  }

  return errors;
};

export const Component = ({ className, message, loading, handleSubmit, dispatch }) => {
  const [showOldPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showConfirmButtom, setShowConfirmButton] = React.useState(false);

  useEffect(() => {
    if (
      oldPassword != '' &&
      newPassword != '' &&
      confirmPassword != '' &&
      newPassword == confirmPassword &&
      confirmPassword != oldPassword
    ) {
      setShowConfirmButton(true);
    } else {
      setShowConfirmButton(false);
    }
  }, [oldPassword, newPassword, confirmPassword]);
  return (
    <Form className={className} onSubmit={handleSubmit}>
      {/* //====================M001=====[==================== */}

      <div className="settings-form-fields">
        <Row>
          <Col sm={12} md={5}>
            <span>Old Password</span>
            <br />
            {showOldPassword ? (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-open"
                className="showHide-icon"
                onClick={() => {
                  setShowPassword(false);
                }}
              />
            ) : (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-close"
                className="showHide-icon"
                onClick={() => {
                  setShowPassword(true);
                }}
              />
            )}
            <Field
              name="oldPassword"
              type={showOldPassword ? 'text' : 'password'}
              component={FormField}
              label="Old Password"
              validate={passwordPolicy}
              disabled={loading}
              onChange={e => setOldPassword(e.target.value)}
              required
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={5}>
            <span>New Password</span>
            <br />
            {showNewPassword ? (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-open"
                className="showHide-icon"
                onClick={() => {
                  setShowNewPassword(false);
                }}
              />
            ) : (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-close"
                className="showHide-icon"
                onClick={() => {
                  setShowNewPassword(true);
                }}
              />
            )}
            <Field
              name="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              component={FormField}
              label="New Password"
              validate={passwordPolicy}
              disabled={loading}
              onChange={e => setNewPassword(e.target.value)}
              required
            />
          </Col>
          <Col sm={12} md={5}>
            <span>Confirm New Password</span>
            <br />
            {showConfirmPassword ? (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-open"
                className="showHide-icon"
                onClick={() => {
                  setShowConfirmPassword(false);
                }}
              />
            ) : (
              <Glyphicon
                glyph="glyphicon glyphicon-eye-close"
                className="showHide-icon"
                onClick={() => {
                  setShowConfirmPassword(true);
                }}
              />
            )}
            <Field
              name="confirmNewPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              component={FormField}
              label="Confirm New Password"
              validate={passwordPolicy}
              disabled={loading}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
          </Col>
        </Row>
      </div>
      {/* //====================M001=====[==================== */}

      {/* <hr className="settings-hr" /> */}
      {message.error && <p className="text-danger">{message.error}</p>}
      {message.message && <p className="text-success">{message.message}</p>}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button
          type="submit"
          bsStyle="primary"
          style={{ width: '25%', marginTop: '15px' }}
          disabled={showConfirmButtom ? false : true}
          onClick={() => {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            dispatch(change('ChangePassword', 'oldPassword', ''));
            dispatch(change('ChangePassword', 'newPassword', ''));
            dispatch(change('ChangePassword', 'confirmNewPassword', ''));
            handleSubmit();
          }}
        >
          Confirm
        </Button>
      </div>

      <hr className="settings-hr" />

      <div className="hidden-xs hidden-sm hidden-md hidden-lg">
        <Button type="submit" bsStyle="primary" className="btn-block" disabled={loading}>
          Login
        </Button>
        <Link href="/signup" to="/signup">
          <Button type="submit" className="btn-block" disabled={loading}>
            Signup
          </Button>
        </Link>
      </div>
    </Form>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.object,
  loading: PropTypes.bool,
};

Component.defaultProps = {
  className: null,
  message: null,
  loading: false,
};

export default reduxForm({
  form: 'ChangePassword', // a unique identifier for this form
  validate,
})(Component);

export const signUp = (obj, history) => ({
  type: 'SIGNUP',
  obj,
  history,
});

export const signUpSuccess = data => ({
  type: 'SIGNUP_SUCCESS',
  data,
});

export const signUpFail = error => ({
  type: 'SIGNUP_FAIL',
  error,
});

export const logIn = (obj, history, redirectUrl) => ({
  type: 'LOGIN',
  obj,
  history,
  redirectUrl,
});

export const resetState = obj => ({
  type: 'RESET_STATE',
  obj,
});

export const logInSuccess = payload => ({
  type: 'LOGIN_SUCCESS',
  payload,
  data: payload,
});

export const logInFail = error => ({
  type: 'LOGIN_FAIL',
  error,
});

export const logOut = () => ({
  type: 'LOGOUT',
});

export const logOutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const logOutFail = error => ({
  type: 'LOGOUT_FAIL',
  error,
});

export const changePassword = obj => ({
  type: 'CHANGE_USER_PASSWORD',
  obj,
});

export const changePasswordSuccess = obj => ({
  type: 'CHANGE_PASSWORD_SUCCESS',
  obj,
});

export const changePasswordFail = (obj, error) => ({
  type: 'CHANGE_PASSWORD_FAIL',
  obj,
  error,
});

export const getCurrentUser = (obj, history, redirectUrl) => ({
  type: 'GET_CURRENT_USER',
  obj,
  history,
  redirectUrl,
});

export const getCurrentUserSuccess = data => ({
  type: 'GET_CURRENT_USER_SUCCESS',
  data,
});

export const getCurrentUserFail = error => ({
  type: 'GET_CURRENT_USER_FAIL',
  error,
});

export const removeUser = obj => ({
  type: 'REMOVE_USER',
  obj,
});

export const removeUserSuccess = obj => ({
  type: 'REMOVE_USER_SUCCESS',
  obj,
});

export const removeUserFail = (obj, error) => ({
  type: 'REMOVE_USER_FAIL',
  obj,
  error,
});

export const userDeleteModal = obj => ({
  type: 'USER_DELETE_MODAL',
  obj,
});

export const setLoader = obj => ({
  type: 'SET_LOADER',
  obj,
});

export const ResetPassword = (obj, history, redirectUrl) => ({
  type: 'RESET_PASSWORD',
  obj,
  history,
  redirectUrl,
});

export const getPersonMatrixDetails = (id, userEntity) => ({
  type: 'GET_PERSON_MATRIX_DETAILS',
  id,
  userEntity,
});

export const getPersonMatrixDetailsSuccess = data => ({
  type: 'GET_PERSON_MATRIX_DETAILS_SUCCESS',
  data,
});

export const getPersonMatrixDetailsFail = error => ({
  type: 'GET_PERSON_MATRIX_DETAILS_FAILURE',
  error,
});

export const updatePersonMatrixDetails = (
  psnId,
  psnEntity,
  insertXml,
  deleteXml,
  schemaId,
  schemaFieldId
) => ({
  type: 'UPDATE_PERSON_MATRIX_DETAILS',
  psnId,
  psnEntity,
  insertXml,
  deleteXml,
  schemaId,
  schemaFieldId,
});

export const updatePersonMatrixDetailsSuccess = data => ({
  type: 'UPDATE_PERSON_MATRIX_DETAILS_SUCCESS',
  data,
});

export const updatePersonMatrixDetailsFail = error => ({
  type: 'UPDATE_PERSON_MATRIX_DETAILS_FAILURE',
  error,
});

export const getAccountManager = user => ({
  type: 'GET_ACCOUNT_MANAGER',
  user,
});

export const getAccountManagerSuccess = data => ({
  type: 'GET_ACCOUNT_MANAGER_SUCCESS',
  data,
});

export const getAccountManagerFail = error => ({
  type: 'GET_ACCOUNT_MANAGER_FAILURE',
  error,
});

export const SendEmail = (
  emailSubject,
  emailBodyParameterName,
  fromSenderId,
  toIds,
  ccIds,
  bccIds,
  headerParams
) => ({
  type: 'SEND_EMAIL',
  emailSubject,
  emailBodyParameterName,
  fromSenderId,
  toIds,
  ccIds,
  bccIds,
  headerParams,
});

export const SendEmailSuccess = data => ({
  type: 'SEND_EMAIL_SUCCESS',
  data,
});

export const SendEmailFail = error => ({
  type: 'SEND_EMAIL_FAILURE',
  error,
});

export const getUserUnsubLastSentEmail = id => ({
  type: 'GET_USER_UNSUB_LAST_SENT_EMAIL',
  id,
});

export const getUserUnsubLastSentEmailSuccess = data => ({
  type: 'GET_USER_UNSUB_LAST_SENT_EMAIL_SUCCESS',
  data,
});

export const getUserUnsubLastSentEmailFail = error => ({
  type: 'GET_USER_UNSUB_LAST_SENT_EMAIL_FAILURE',
  error,
});

export const updateUserUnsubLastSentEmail = id => ({
  type: 'UPDATE_USER_UNSUB_LAST_SENT_EMAIL',
  id,
});

export const updateUserUnsubLastSentEmailSuccess = data => ({
  type: 'UPDATE_USER_UNSUB_LAST_SENT_EMAIL_SUCCESS',
  data,
});

export const updateUserUnsubLastSentEmailFail = error => ({
  type: 'UPDATE_USER_UNSUB_LAST_SENT_EMAIL_FAILURE',
  error,
});

/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 15 July 2021 : Aman
    * M-0003415: Create Account screen pops when typing in name or email address

**************************************************************************************************************
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SignupForm from './components/SignupForm';
import SignupSuccess from './components/SignupSuccess';
import FormContainer from '../../components/FormContainer';
import BlurredHomePage from '../BlurredHomePage';
import MainLayout from '../../layouts/Main';

import * as userActions from '../../actionCreators/User';
import * as userModalActions from '../../actionCreators/UserModal';

const Container = ({
  user,
  history,
  location,
  location: { search },
  signUp,
  initialValues,
  hideHomePageModal,
  resetState,
}) => {
  const urlParams = new URLSearchParams(search);
  const success = urlParams.get('success');

  useEffect(() => {
    resetState();
  }, []);

  if (success === 'true' || (user && user.signUpSuccess)) {
    return (
      <FormContainer>
        <SignupSuccess
          backToHome={v => {
            if (v === 'hide') {
              hideHomePageModal();
            }
          }}
        />
      </FormContainer>
    );
  }

  return (
    //M001
    <div className="login-signup-page" style={{ overflow: 'hidden' }}>
      {/* //M001 */}
      {location.state ? (
        <MainLayout>
          <BlurredHomePage />
        </MainLayout>
      ) : (
        <FormContainer>
          <div style={{ paddingTop: 15 }} />
          <div
            style={{
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            Signup
          </div>
          <SignupForm
            className="box-signup-form"
            onSubmit={obj => signUp(obj, history)}
            message={user.signUpError}
            loading={user.loading}
            nameRequired={urlParams.get('name') == 'false' ? false : true}
            initialValues={{
              full_name: (initialValues && initialValues.name) || urlParams.get('name'),
              email: (initialValues && initialValues.email) || urlParams.get('email'),
            }}
          />
        </FormContainer>
      )}
    </div>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch => ({
  signUp: bindActionCreators(userActions.signUp, dispatch),
  resetState: bindActionCreators(userActions.resetState, dispatch),
  hideHomePageModal: bindActionCreators(userModalActions.hideHomePageModal, dispatch),
});

Container.propTypes = {
  user: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  signUp: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  hideHomePageModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
